@media print {
    .noPrint {
      display: none;
    }
    .MuiPaper-root {
      display: none;
    }
    .page_break {
      page-break-after: always;
    }
  }